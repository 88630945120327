import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';

import {Observable} from 'rxjs/Observable';
import { flatMap, finalize } from 'rxjs/operators';
import 'rxjs/add/observable/of';
import 'rxjs/add/observable/throw';
import { catchError } from 'rxjs/operators';

import { SessionModel } from '../session/models/session-model';

import { HttpErrorHandlerService } from './http-error-handler.service';
import { StoreService } from './store.service';

import { Subject } from 'rxjs/Subject';
import { ISessionDetails } from '../models/session-details.interface';
import {
  IProfile,
  ProfileUserType,
  ProfileDealerRole,
  ProfileAllyRole,
  ProfileBirthrightAccess,
  ProfileRideReturnGroup
} from '../models/profile.interface';
import { assign, filter, find, get, includes, map, pick, values, startsWith } from 'lodash';
import { OktaAuthService, IOidcClientConfig, OktaToken } from './okta-auth/okta-auth.service';
import { AnalyticsService } from '.';

type SessionPersistenceAttrKey = keyof {
  willDeleteSession,
  hasActiveSession
}
namespace SessionPersistenceAttrKey {
  export const HasActiveSession: SessionPersistenceAttrKey = 'hasActiveSession';
  export const WillDeleteSession: SessionPersistenceAttrKey = 'willDeleteSession';
}

type SessionManagementAttrKey = keyof {
  profile,
  sessionId,
  sessionToken,
  sessionDetails,
  allyUserProperties,
  helpDeskProperties,
  allyAdminProperties,
  allLaunchDarklyFlags,
  launchDarklyDMFlag,
  launchDarklyADRFlag,
  launchDarklySCFlag,
  launchDarklyDashMSGFlag,
  // defaultFlagData,
}
export namespace SessionManagementAttrKey {
  export const profile: SessionManagementAttrKey = "profile";
  export const sessionId: SessionManagementAttrKey = "sessionId";
  export const sessionToken: SessionManagementAttrKey = "sessionToken";
  export const sessionDetails: SessionManagementAttrKey = "sessionDetails";
  export const allyUserProperties: SessionManagementAttrKey = "allyUserProperties";
  export const helpDeskProperties: SessionManagementAttrKey = "helpDeskProperties";
  export const allyAdminProperties: SessionManagementAttrKey = "allyAdminProperties";
  export const allLaunchDarklyFlags: SessionManagementAttrKey = "allLaunchDarklyFlags";
  export const launchDarklyDMFlag: SessionManagementAttrKey = "launchDarklyDMFlag";
  export const launchDarklyADRFlag: SessionManagementAttrKey = "launchDarklyADRFlag";
  export const launchDarklySCFlag: SessionManagementAttrKey = "launchDarklySCFlag";
  export const launchDarklyDashMSGFlag: SessionManagementAttrKey = "launchDarklyDashMSGFlag";
  // export const defaultFlagData: SessionManagementAttrKey = "defaultFlagData";
}

interface IUserGroup {
  name: string;
  dealershipType: string;
  description: string;
}

interface IUserDetails {
  cuid: string;
  groups: IUserGroup[];
  profile: IProfile;
  primaryDealer?: string;
}

export type ExternalUserGroup = keyof {
  'Ally Users', 'Mitsubishi Users'
}
export namespace ExternalUserGroup {
  export const Ally: ExternalUserGroup = 'Ally Users';
  export const Mitsubishi: ExternalUserGroup = 'Mitsubishi Users';
}

// we need a currentUser service for this stuff and a number of accessors that shouldn't be in here
export interface IBranchUserRequestStatus {
  pendingRequests: boolean;
  pendingApprovalRequests: boolean;
}

type SessionManagementAttrValue = IProfile | ISessionDetails | string;

@Injectable()
export class SessionManagementService {

  hasSession$: Subject<boolean> = new Subject<boolean>();

  handleError;
  private httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
  private branchUserRequestStatus: IBranchUserRequestStatus;

  constructor(
    httpErrorHandler: HttpErrorHandlerService,
    private http: HttpClient,
    private storeService: StoreService,
    private oktaAuthService: OktaAuthService,
    private analytics: AnalyticsService,
  ) {
    this.storeService.write(SessionPersistenceAttrKey.HasActiveSession, true);
    window.addEventListener('storage', evt => this.handleStorageChange(evt.key));
    this.handleError = httpErrorHandler.createHandleError('refreshSession');
  }


  get willDeleteSessionOnNextVisit(): boolean {
    return this.storeService.read<boolean>(SessionPersistenceAttrKey.WillDeleteSession);
  }
  set willDeleteSessionOnNextVisit(willDelete: boolean) {
    if (willDelete) {
      this.storeService.write(SessionPersistenceAttrKey.WillDeleteSession, willDelete);
    }
    else {
      this.storeService.delete(SessionPersistenceAttrKey.WillDeleteSession);
    }
  }

  get hasSession(): boolean {
    return !!this.sessionId;
  }

  get hasBirthright(): boolean {
    return includes(values(ProfileBirthrightAccess), get(this.profile, 'birthright'));
  }

  get profile(): IProfile { // we need consistent props, and clean up references to profile and sessionDetails
    const profile  = this.storeService.read<IProfile>(SessionManagementAttrKey.profile);
    return !!profile ? profile && { ...profile, userName: profile.login } : null;
  }

  get userName(): string {
    return get(this.profile, 'login');
  }

  get sessionId(): string {
    return this.storeService.read<string>(SessionManagementAttrKey.sessionId);
  }

  get sessionToken(): string {
    return this.storeService.read<string>(SessionManagementAttrKey.sessionToken);
  }

  get sessionDetails(): ISessionDetails {
    return pick(this.profile, 'userName', 'userType', 'userId');
  }

  get isBranchUser(): boolean {
    return get(this.profile, 'userType') == ProfileUserType.branch;
  }

  get isDealershipUser(): boolean {
    return get(this.profile, 'userType') == ProfileUserType.dealership;
  }

  // Used for getting all the LD flags from storage
  get allLaunchDarklyFlags(): Object {
    return this.storeService.read<Object>(SessionManagementAttrKey.allLaunchDarklyFlags);
  }
  
  // Used for getting the LD Document-Manager flag from storage
  get launchDarklyDM(): boolean {
    return this.storeService.read<boolean>(SessionManagementAttrKey.launchDarklyDMFlag);
  }

  // Used for getting the LD dash_app_adr flag from storage
  get launchDarklyADR(): boolean {
    return this.storeService.read<boolean>(SessionManagementAttrKey.launchDarklyADRFlag);
  }

  // Used for getting the LD dash_app_sc flag from storage
  get launchDarklySC(): boolean {
    return this.storeService.read<boolean>(SessionManagementAttrKey.launchDarklySCFlag);
  }

   // Used for getting the LD dash-message flag from storage
   get launchDarklyDashMSG(): any {
    return this.storeService.read<any>(SessionManagementAttrKey.launchDarklyDashMSGFlag);
  }

  // This is triggering Manage User hidden or not
  get canManage(): boolean {
    if (this.isBranchUser) {
      const roles = [ProfileAllyRole.admin, ProfileAllyRole.helpdesk];
      return includes(roles, get(this.profile, 'allyRole'))
        || get(this.branchUserRequestStatus, 'pendingApprovalRequests');
    }
    else if (this.isDealershipUser) {
      return get(this.profile, 'dealerRole') == ProfileDealerRole.admin;
    }

    return false;
  }

  get canSearch(): boolean {
    return this.canManage && !(get(this.profile, 'allyRole') === ProfileAllyRole.user);
  }

  get externalGroups(): ExternalUserGroup[] {
    if(this.profile !== null ){
      return  this.profile['exernalGroups'] || [];
    }
    return [];
  }

  /*
    GETTERS for manageUser from allyUserPorperties in session
  */
  get allyAdminProperties(): string {
    return this.storeService.read<any>(SessionManagementAttrKey.allyAdminProperties);
  }

  get helpDeskProperties(): string {
    return this.storeService.read<any>(SessionManagementAttrKey.helpDeskProperties);
  }

  get allyUserProperties(): string {
    return this.storeService.read<any>(SessionManagementAttrKey.allyUserProperties);
  }

  get allyAdminManageUser(): string {
    const obj = this.storeService.read<any>(SessionManagementAttrKey.allyAdminProperties);
    return obj.manageUser;
  }

  get allyAdminManageUserBoolean(): boolean {
    if(this.allyAdminManageUser == 'Y'){
      return true;
    }
    if(this.allyAdminManageUser == 'N'){
      return false;
    }
  }

  get helpDeskManageUser(): string {
    const obj = this.storeService.read<any>(SessionManagementAttrKey.helpDeskProperties);
    return obj.manageUser;
  }

  get helpDeskManageUserBoolean(): boolean {
    if(this.helpDeskManageUser == 'Y'){
      return true;
    }
    if(this.helpDeskManageUser == 'N'){
      return false;
    }
  }

  get allyUserManageUser(): string {
    const obj = this.storeService.read<any>(SessionManagementAttrKey.allyUserProperties);
    return obj.manageUser;
  }

  get allyUserManageUserBoolean(): boolean {
    if(this.allyUserManageUser == 'Y'){
      return true;
    }
    if(this.allyUserManageUser == 'N'){
      return false;
    }
  }
  // Checks the role in profile and returns boolean based on info from allyUserProperties
  get showManage(): boolean {
    if(this.isBranchUser) {
      const role = get(this.profile, 'allyRole');

      if((role == ProfileAllyRole.admin) && this.allyAdminManageUserBoolean){
        return true;
      }
      if((role == ProfileAllyRole.helpdesk) && this.helpDeskManageUserBoolean){
        return true;
      }
      if((role == ProfileAllyRole.user) && this.allyUserManageUserBoolean){
        return true;
      }
      return false;
    }
    if(this.isDealershipUser) {
      return get(this.profile, 'dealerRole') == ProfileDealerRole.admin;
    }
  }

  /*
    GETTERS for searchUser from allyUserPorperties in session
  */
  get allyAdminSearchUser(): string {
    const obj = this.storeService.read<any>(SessionManagementAttrKey.allyAdminProperties);
    return obj.searchUser;
  }

  get allyAdminSearchUserBoolean(): boolean {
    if(this.allyAdminSearchUser == 'Y'){
      return true;
    }
    if(this.allyAdminSearchUser == 'N'){
      return false;
    }
  }

  get helpDeskSearchUser(): string {
    const obj = this.storeService.read<any>(SessionManagementAttrKey.helpDeskProperties);
    return obj.searchUser;
  }

  get helpDeskSearchUserBoolean(): boolean {
    if(this.helpDeskSearchUser == 'Y'){
      return true;
    }
    if(this.helpDeskSearchUser == 'N'){
      return false;
    }
  }

  get allyUserSearchUser(): string {
    const obj = this.storeService.read<any>(SessionManagementAttrKey.allyUserProperties);
    return obj.searchUser;
  }

  get allyUserSearchUserBoolean(): boolean {
    if(this.allyUserSearchUser == 'Y'){
      return true;
    }
    if(this.allyUserSearchUser == 'N'){
      return false;
    }
  }

  get showSearch(): boolean {
    if(this.isBranchUser) {
      const role = get(this.profile, 'allyRole');

      if((role == ProfileAllyRole.admin) && this.allyAdminSearchUserBoolean){
        return true;
      }
      if((role == ProfileAllyRole.helpdesk) && this.helpDeskSearchUserBoolean){
        return true;
      }
      if((role == ProfileAllyRole.user) && this.allyUserSearchUserBoolean){
        return true;
      }
      return false;
    }
    if(this.isDealershipUser) {
      return true;
    }
  }

  /*
  GETTERS for editDealerUserInfo from allyUserPorperties in session
  */
  get allyAdminEditDealerUserInfo(): string {
    const obj = this.storeService.read<any>(SessionManagementAttrKey.allyAdminProperties);
    return obj.editDealerUserInfo;
  }

  get allyAdminEditDealerUserInfoBoolean(): boolean {
    if(this.allyAdminEditDealerUserInfo == 'Y'){
      return true;
    }
    if(this.allyAdminEditDealerUserInfo == 'N'){
      return false;
    }
  }

  get helpDeskEditDealerUserInfo(): string {
    const obj = this.storeService.read<any>(SessionManagementAttrKey.helpDeskProperties);
    return obj.editDealerUserInfo;
  }

  get helpDeskEditDealerUserInfoBoolean(): boolean {
    if(this.helpDeskEditDealerUserInfo == 'Y'){
      return true;
    }
    if(this.helpDeskEditDealerUserInfo == 'N'){
      return false;
    }
  }

  get allyUserEditDealerUserInfo(): string {
    const obj = this.storeService.read<any>(SessionManagementAttrKey.allyUserProperties);
    return obj.editDealerUserInfo;
  }

  get allyUserEditDealerUserInfoBoolean(): boolean {
    if(this.allyUserEditDealerUserInfo == 'Y'){
      return true;
    }
    if(this.allyUserEditDealerUserInfo == 'N'){
      return false;
    }
  }

  get showEditDealerUserInfo(): boolean {
    if(this.isBranchUser) {
      const role = get(this.profile, 'allyRole');

      if((role == ProfileAllyRole.admin) && this.allyAdminEditDealerUserInfoBoolean){
        return true;
      }
      if((role == ProfileAllyRole.helpdesk) && this.helpDeskEditDealerUserInfoBoolean){
        return true;
      }
      if((role == ProfileAllyRole.user) && this.allyUserEditDealerUserInfoBoolean){
        return true;
      }
      return false;
    }
    if(this.isDealershipUser) {
      return true;
    }
  }

  /*
  GETTERS for editAllyUserInfo from allyUserPorperties in session
  */
  get allyAdminEditAllyUserInfo(): string {
    const obj = this.storeService.read<any>(SessionManagementAttrKey.allyAdminProperties);
    return obj.editAllyUserInfo;
  }

  get allyAdminEditAllyUserInfoBoolean(): boolean {
    if(this.allyAdminEditAllyUserInfo == 'Y'){
      return true;
    }
    if(this.allyAdminEditAllyUserInfo == 'N'){
      return false;
    }
  }

  get helpDeskEditAllyUserInfo(): string {
    const obj = this.storeService.read<any>(SessionManagementAttrKey.helpDeskProperties);
    return obj.editAllyUserInfo;
  }

  get helpDeskEditAllyUserInfoBoolean(): boolean {
    if(this.helpDeskEditAllyUserInfo == 'Y'){
      return true;
    }
    if(this.helpDeskEditAllyUserInfo == 'N'){
      return false;
    }
  }

  get allyUserEditAllyUserInfo(): string {
    const obj = this.storeService.read<any>(SessionManagementAttrKey.allyUserProperties);
    return obj.editAllyUserInfo;
  }

  get allyUserEditAllyUserInfoBoolean(): boolean {
    if(this.allyUserEditAllyUserInfo == 'Y'){
      return true;
    }
    if(this.allyUserEditAllyUserInfo == 'N'){
      return false;
    }
  }

  get showEditAllyUserInfo(): boolean {
    if(this.isBranchUser) {
      const role = get(this.profile, 'allyRole');

      if((role == ProfileAllyRole.admin) && this.allyAdminEditAllyUserInfoBoolean){
        return true;
      }
      if((role == ProfileAllyRole.helpdesk) && this.helpDeskEditAllyUserInfoBoolean){
        return true;
      }
      if((role == ProfileAllyRole.user) && this.allyUserEditAllyUserInfoBoolean){
        return true;
      }
      return false;
    }
    if(this.isDealershipUser) {
      return true;
    }
  }

  /*
  GETTERS for addDealerAppReports from allyUserPorperties in session
  */
  get allyAdminAddDealerAppReports(): string {
    const obj = this.storeService.read<any>(SessionManagementAttrKey.allyAdminProperties);
    return obj.addDealerAppReports;
  }

  get allyAdminAddDealerAppReportsBoolean(): boolean {
    if(this.allyAdminAddDealerAppReports == 'Y'){
      return true;
    }
    if(this.allyAdminAddDealerAppReports == 'N'){
      return false;
    }
  }

  get helpDeskAddDealerAppReports(): string {
    const obj = this.storeService.read<any>(SessionManagementAttrKey.helpDeskProperties);
    return obj.addDealerAppReports;
  }

  get helpDeskAddDealerAppReportsBoolean(): boolean {
    if(this.helpDeskAddDealerAppReports == 'Y'){
      return true;
    }
    if(this.helpDeskAddDealerAppReports == 'N'){
      return false;
    }
  }

  get allyUserAddDealerAppReports(): string {
    const obj = this.storeService.read<any>(SessionManagementAttrKey.allyUserProperties);
    return obj.addDealerAppReports;
  }

  get allyUserAddDealerAppReportsBoolean(): boolean {
    if(this.allyUserAddDealerAppReports == 'Y'){
      return true;
    }
    if(this.allyUserAddDealerAppReports == 'N'){
      return false;
    }
  }

  get showAddDealerAppReports(): boolean {
    if(this.isBranchUser) {
      const role = get(this.profile, 'allyRole');

      if((role == ProfileAllyRole.admin) && this.allyAdminAddDealerAppReportsBoolean){
        return true;
      }
      if((role == ProfileAllyRole.helpdesk) && this.helpDeskAddDealerAppReportsBoolean){
        return true;
      }
      if((role == ProfileAllyRole.user) && this.allyUserAddDealerAppReportsBoolean){
        return true;
      }
      return false;
    }
    if(this.isDealershipUser) {
      return true;
    }
  }

  /*
      GETTERS for addManageUserApp from allyUserPorperties in session
      */
  get allyAdminAddManageUserApp(): string {
    const obj = this.storeService.read<any>(SessionManagementAttrKey.allyAdminProperties);
    return obj.addManageUserApp;
  }

  get allyAdminAddManageUserAppBoolean(): boolean {
    if(this.allyAdminAddManageUserApp == 'Y'){
      return true;
    }
    if(this.allyAdminAddManageUserApp == 'N'){
      return false;
    }
  }

  get helpDeskAddManageUserApp(): string {
    const obj = this.storeService.read<any>(SessionManagementAttrKey.helpDeskProperties);
    return obj.addManageUserApp;
  }

  get helpDeskAddManageUserAppBoolean(): boolean {
    if(this.helpDeskAddManageUserApp == 'Y'){
      return true;
    }
    if(this.helpDeskAddManageUserApp == 'N'){
      return false;
    }
  }

  get allyUserAddManageUserApp(): string {
    const obj = this.storeService.read<any>(SessionManagementAttrKey.allyUserProperties);
    return obj.addManageUserApp;
  }

  get allyUserAddManageUserAppBoolean(): boolean {
    if(this.allyUserAddManageUserApp == 'Y'){
      return true;
    }
    if(this.allyUserAddManageUserApp == 'N'){
      return false;
    }
  }

  get showAddManageUserApp(): boolean {
    if(this.isBranchUser) {
      const role = get(this.profile, 'allyRole');

      if((role == ProfileAllyRole.admin) && this.allyAdminAddManageUserAppBoolean){
        return true;
      }
      if((role == ProfileAllyRole.helpdesk) && this.helpDeskAddManageUserAppBoolean){
        return true;
      }
      if((role == ProfileAllyRole.user) && this.allyUserAddManageUserAppBoolean){
        return true;
      }
      return false;
    }
    if(this.isDealershipUser) {
      return true;
    }
  }

  /*
  GETTERS for addAllyUserAppReports from allyUserPorperties in session
  */
  get allyAdminAddAllyUserAppReports(): string {
    const obj = this.storeService.read<any>(SessionManagementAttrKey.allyAdminProperties);
    return obj.addAllyUserAppReports;
  }

  get allyAdminAddAllyUserAppReportsBoolean(): boolean {
    if(this.allyAdminAddAllyUserAppReports == 'Y'){
      return true;
    }
    if(this.allyAdminAddAllyUserAppReports == 'N'){
      return false;
    }
  }

  get helpDeskAddAllyUserAppReports(): string {
    const obj = this.storeService.read<any>(SessionManagementAttrKey.helpDeskProperties);
    return obj.addAllyUserAppReports;
  }

  get helpDeskAddAllyUserAppReportsBoolean(): boolean {
    if(this.helpDeskAddAllyUserAppReports == 'Y'){
      return true;
    }
    if(this.helpDeskAddAllyUserAppReports == 'N'){
      return false;
    }
  }

  get allyUserAddAllyUserAppReports(): string {
    const obj = this.storeService.read<any>(SessionManagementAttrKey.allyUserProperties);
    return obj.addAllyUserAppReports;
  }

  get allyUserAddAllyUserAppReportsBoolean(): boolean {
    if(this.allyUserAddAllyUserAppReports == 'Y'){
      return true;
    }
    if(this.allyUserAddAllyUserAppReports == 'N'){
      return false;
    }
  }

  get showAddAllyUserAppReports(): boolean {
    if(this.isBranchUser) {
      const role = get(this.profile, 'allyRole');

      if((role == ProfileAllyRole.admin) && this.allyAdminAddAllyUserAppReportsBoolean){
        return true;
      }
      if((role == ProfileAllyRole.helpdesk) && this.helpDeskAddAllyUserAppReportsBoolean){
        return true;
      }
      if((role == ProfileAllyRole.user) && this.allyUserAddAllyUserAppReportsBoolean){
        return true;
      }
      return false;
    }
    if(this.isDealershipUser) {
      return true;
    }
  }

  /*
  GETTERS for removeDealerAppReports from allyUserPorperties in session
  */
  get allyAdminRemoveDealerAppReports(): string {
    const obj = this.storeService.read<any>(SessionManagementAttrKey.allyAdminProperties);
    return obj.removeDealerAppReports;
  }

  get allyAdminRemoveDealerAppReportsBoolean(): boolean {
    if(this.allyAdminRemoveDealerAppReports == 'Y'){
      return true;
    }
    if(this.allyAdminRemoveDealerAppReports == 'N'){
      return false;
    }
  }

  get helpDeskRemoveDealerAppReports(): string {
    const obj = this.storeService.read<any>(SessionManagementAttrKey.helpDeskProperties);
    return obj.removeDealerAppReports;
  }

  get helpDeskRemoveDealerAppReportsBoolean(): boolean {
    if(this.helpDeskRemoveDealerAppReports == 'Y'){
      return true;
    }
    if(this.helpDeskRemoveDealerAppReports == 'N'){
      return false;
    }
  }

  get allyUserRemoveDealerAppReports(): string {
    const obj = this.storeService.read<any>(SessionManagementAttrKey.allyUserProperties);
    return obj.removeDealerAppReports;
  }

  get allyUserRemoveDealerAppReportsBoolean(): boolean {
    if(this.allyUserRemoveDealerAppReports == 'Y'){
      return true;
    }
    if(this.allyUserRemoveDealerAppReports == 'N'){
      return false;
    }
  }

  get showRemoveDealerAppReports(): boolean {
    if(this.isBranchUser) {
      const role = get(this.profile, 'allyRole');

      if((role == ProfileAllyRole.admin) && this.allyAdminRemoveDealerAppReportsBoolean){
        return true;
      }
      if((role == ProfileAllyRole.helpdesk) && this.helpDeskRemoveDealerAppReportsBoolean){
        return true;
      }
      if((role == ProfileAllyRole.user) && this.allyUserRemoveDealerAppReportsBoolean){
        return true;
      }
      return false;
    }
    if(this.isDealershipUser) {
      return true;
    }
  }

  /*
  GETTERS for removeManageUserApp from allyUserPorperties in session
  */
  get allyAdminRemoveManageUserApp(): string {
    const obj = this.storeService.read<any>(SessionManagementAttrKey.allyAdminProperties);
    return obj.removeManageUserApp;
  }

  get allyAdminRemoveManageUserAppBoolean(): boolean {
    if(this.allyAdminRemoveManageUserApp == 'Y'){
      return true;
    }
    if(this.allyAdminRemoveManageUserApp == 'N'){
      return false;
    }
  }

  get helpDeskRemoveManageUserApp(): string {
    const obj = this.storeService.read<any>(SessionManagementAttrKey.helpDeskProperties);
    return obj.removeManageUserApp;
  }

  get helpDeskRemoveManageUserAppBoolean(): boolean {
    if(this.helpDeskRemoveManageUserApp == 'Y'){
      return true;
    }
    if(this.helpDeskRemoveManageUserApp == 'N'){
      return false;
    }
  }

  get allyUserRemoveManageUserApp(): string {
    const obj = this.storeService.read<any>(SessionManagementAttrKey.allyUserProperties);
    return obj.removeManageUserApp;
  }

  get allyUserRemoveManageUserAppBoolean(): boolean {
    if(this.allyUserRemoveManageUserApp == 'Y'){
      return true;
    }
    if(this.allyUserRemoveManageUserApp == 'N'){
      return false;
    }
  }

  get showRemoveManageUserApp(): boolean {
    if(this.isBranchUser) {
      const role = get(this.profile, 'allyRole');

      if((role == ProfileAllyRole.admin) && this.allyAdminRemoveManageUserAppBoolean){
        return true;
      }
      if((role == ProfileAllyRole.helpdesk) && this.helpDeskRemoveManageUserAppBoolean){
        return true;
      }
      if((role == ProfileAllyRole.user) && this.allyUserRemoveManageUserAppBoolean){
        return true;
      }
      return false;
    }
    if(this.isDealershipUser) {
      return true;
    }
  }

  /*
  GETTERS for removeAllyUserAppReports from allyUserPorperties in session
  */
  get allyAdminRemoveAllyUserAppReports(): string {
    const obj = this.storeService.read<any>(SessionManagementAttrKey.allyAdminProperties);
    return obj.removeAllyUserAppReports;
  }

  get allyAdminRemoveAllyUserAppReportsBoolean(): boolean {
    if(this.allyAdminRemoveAllyUserAppReports == 'Y'){
      return true;
    }
    if(this.allyAdminRemoveAllyUserAppReports == 'N'){
      return false;
    }
  }

  get helpDeskRemoveAllyUserAppReports(): string {
    const obj = this.storeService.read<any>(SessionManagementAttrKey.helpDeskProperties);
    return obj.removeAllyUserAppReports;
  }

  get helpDeskRemoveAllyUserAppReportsBoolean(): boolean {
    if(this.helpDeskRemoveAllyUserAppReports == 'Y'){
      return true;
    }
    if(this.helpDeskRemoveAllyUserAppReports == 'N'){
      return false;
    }
  }

  get allyUserRemoveAllyUserAppReports(): string {
    const obj = this.storeService.read<any>(SessionManagementAttrKey.allyUserProperties);
    return obj.removeAllyUserAppReports;
  }

  get allyUserRemoveAllyUserAppReportsBoolean(): boolean {
    if(this.allyUserRemoveAllyUserAppReports == 'Y'){
      return true;
    }
    if(this.allyUserRemoveAllyUserAppReports == 'N'){
      return false;
    }
  }

  get showRemoveAllyUserAppReports(): boolean {
    if(this.isBranchUser) {
      const role = get(this.profile, 'allyRole');

      if((role == ProfileAllyRole.admin) && this.allyAdminRemoveAllyUserAppReportsBoolean){
        return true;
      }
      if((role == ProfileAllyRole.helpdesk) && this.helpDeskRemoveAllyUserAppReportsBoolean){
        return true;
      }
      if((role == ProfileAllyRole.user) && this.allyUserRemoveAllyUserAppReportsBoolean){
        return true;
      }
      return false;
    }
    if(this.isDealershipUser) {
      return true;
    }
  }

  /*
  GETTERS for dealerUserAddPdn from allyUserPorperties in session
  */
  get allyAdminDealerUserAddPdn(): string {
    const obj = this.storeService.read<any>(SessionManagementAttrKey.allyAdminProperties);
    return obj.dealerUserAddPdn;
  }

  get allyAdminDealerUserAddPdnBoolean(): boolean {
    if(this.allyAdminDealerUserAddPdn == 'Y'){
      return true;
    }
    if(this.allyAdminDealerUserAddPdn == 'N'){
      return false;
    }
  }

  get helpDeskDealerUserAddPdn(): string {
    const obj = this.storeService.read<any>(SessionManagementAttrKey.helpDeskProperties);
    return obj.dealerUserAddPdn;
  }

  get helpDeskDealerUserAddPdnBoolean(): boolean {
    if(this.helpDeskDealerUserAddPdn == 'Y'){
      return true;
    }
    if(this.helpDeskDealerUserAddPdn == 'N'){
      return false;
    }
  }

  get allyUserDealerUserAddPdn(): string {
    const obj = this.storeService.read<any>(SessionManagementAttrKey.allyUserProperties);
    return obj.dealerUserAddPdn;
  }

  get allyUserDealerUserAddPdnBoolean(): boolean {
    if(this.allyUserDealerUserAddPdn == 'Y'){
      return true;
    }
    if(this.allyUserDealerUserAddPdn == 'N'){
      return false;
    }
  }

  get showDealerUserAddPdn(): boolean {
    if(this.isBranchUser) {
      const role = get(this.profile, 'allyRole');

      if((role == ProfileAllyRole.admin) && this.allyAdminDealerUserAddPdnBoolean){
        return true;
      }
      if((role == ProfileAllyRole.helpdesk) && this.helpDeskDealerUserAddPdnBoolean){
        return true;
      }
      if((role == ProfileAllyRole.user) && this.allyUserDealerUserAddPdnBoolean){
        return true;
      }
      return false;
    }
    if(this.isDealershipUser) {
      return true;
    }
  }

  /*
  GETTERS for editDealerUserPendRequest from allyUserPorperties in session
  */
  get allyAdminEditDealerUserPendRequest(): string {
    const obj = this.storeService.read<any>(SessionManagementAttrKey.allyAdminProperties);
    return obj.editDealerUserPendRequest;
  }

  get allyAdminEditDealerUserPendRequestBoolean(): boolean {
    if(this.allyAdminEditDealerUserPendRequest == 'Y'){
      return true;
    }
    if(this.allyAdminEditDealerUserPendRequest == 'N'){
      return false;
    }
  }

  get helpDeskEditDealerUserPendRequest(): string {
    const obj = this.storeService.read<any>(SessionManagementAttrKey.helpDeskProperties);
    return obj.editDealerUserPendRequest;
  }

  get helpDeskEditDealerUserPendRequestBoolean(): boolean {
    if(this.helpDeskEditDealerUserPendRequest == 'Y'){
      return true;
    }
    if(this.helpDeskEditDealerUserPendRequest == 'N'){
      return false;
    }
  }

  get allyUserEditDealerUserPendRequest(): string {
    const obj = this.storeService.read<any>(SessionManagementAttrKey.allyUserProperties);
    return obj.editDealerUserPendRequest;
  }

  get allyUserEditDealerUserPendRequestBoolean(): boolean {
    if(this.allyUserEditDealerUserPendRequest == 'Y'){
      return true;
    }
    if(this.allyUserEditDealerUserPendRequest == 'N'){
      return false;
    }
  }

  get showEditDealerUserPendRequest(): boolean {
    if(this.isBranchUser) {
      const role = get(this.profile, 'allyRole');

      if((role == ProfileAllyRole.admin) && this.allyAdminEditDealerUserPendRequestBoolean){
        return true;
      }
      if((role == ProfileAllyRole.helpdesk) && this.helpDeskEditDealerUserPendRequestBoolean){
        return true;
      }
      if((role == ProfileAllyRole.user) && this.allyUserEditDealerUserPendRequestBoolean){
        return true;
      }
      return false;
    }
    if(this.isDealershipUser) {
      return true;
    }
  }

  /*
  GETTERS for editAllyUserPendRequest from allyUserPorperties in session
  */
  get allyAdminEditAllyUserPendRequest(): string {
    const obj = this.storeService.read<any>(SessionManagementAttrKey.allyAdminProperties);
    return obj.editAllyUserPendRequest;
  }

  get allyAdminEditAllyUserPendRequestBoolean(): boolean {
    if(this.allyAdminEditAllyUserPendRequest == 'Y'){
      return true;
    }
    if(this.allyAdminEditAllyUserPendRequest == 'N'){
      return false;
    }
  }

  get helpDeskEditAllyUserPendRequest(): string {
    const obj = this.storeService.read<any>(SessionManagementAttrKey.helpDeskProperties);
    return obj.editAllyUserPendRequest;
  }

  get helpDeskEditAllyUserPendRequestBoolean(): boolean {
    if(this.helpDeskEditAllyUserPendRequest == 'Y'){
      return true;
    }
    if(this.helpDeskEditAllyUserPendRequest == 'N'){
      return false;
    }
  }

  get allyUserEditAllyUserPendRequest(): string {
    const obj = this.storeService.read<any>(SessionManagementAttrKey.allyUserProperties);
    return obj.editAllyUserPendRequest;
  }

  get allyUserEditAllyUserPendRequestBoolean(): boolean {
    if(this.allyUserEditAllyUserPendRequest == 'Y'){
      return true;
    }
    if(this.allyUserEditAllyUserPendRequest == 'N'){
      return false;
    }
  }

  get showEditAllyUserPendRequest(): boolean {
    if(this.isBranchUser) {
      const role = get(this.profile, 'allyRole');

      if((role == ProfileAllyRole.admin) && this.allyAdminEditAllyUserPendRequestBoolean){
        return true;
      }
      if((role == ProfileAllyRole.helpdesk) && this.helpDeskEditAllyUserPendRequestBoolean){
        return true;
      }
      if((role == ProfileAllyRole.user) && this.allyUserEditAllyUserPendRequestBoolean){
        return true;
      }
      return false;
    }
    if(this.isDealershipUser) {
      return true;
    }
  }

  /*
  GETTERS for removeDealerUser from allyUserPorperties in session
  */
  get allyAdminRemoveDealerUser(): string {
    const obj = this.storeService.read<any>(SessionManagementAttrKey.allyAdminProperties);
    return obj.removeDealerUser;
  }

  get allyAdminRemoveDealerUserBoolean(): boolean {
    if(this.allyAdminRemoveDealerUser == 'Y'){
      return true;
    }
    if(this.allyAdminRemoveDealerUser == 'N'){
      return false;
    }
  }

  get helpDeskRemoveDealerUser(): string {
    const obj = this.storeService.read<any>(SessionManagementAttrKey.helpDeskProperties);
    return obj.removeDealerUser;
  }

  get helpDeskRemoveDealerUserBoolean(): boolean {
    if(this.helpDeskRemoveDealerUser == 'Y'){
      return true;
    }
    if(this.helpDeskRemoveDealerUser == 'N'){
      return false;
    }
  }

  get allyUserRemoveDealerUser(): string {
    const obj = this.storeService.read<any>(SessionManagementAttrKey.allyUserProperties);
    return obj.removeDealerUser;
  }

  get allyUserRemoveDealerUserBoolean(): boolean {
    if(this.allyUserRemoveDealerUser == 'Y'){
      return true;
    }
    if(this.allyUserRemoveDealerUser == 'N'){
      return false;
    }
  }

  get showRemoveDealerUser(): boolean {
    if(this.isBranchUser) {
      const role = get(this.profile, 'allyRole');

      if((role == ProfileAllyRole.admin) && this.allyAdminRemoveDealerUserBoolean){
        return true;
      }
      if((role == ProfileAllyRole.helpdesk) && this.helpDeskRemoveDealerUserBoolean){
        return true;
      }
      if((role == ProfileAllyRole.user) && this.allyUserRemoveDealerUserBoolean){
        return true;
      }
      return false;
    }
    if(this.isDealershipUser) {
      return true;
    }
  }

  /*
  GETTERS for removeAllyUser from allyUserPorperties in session
  */
  get allyAdminRemoveAllyUser(): string {
    const obj = this.storeService.read<any>(SessionManagementAttrKey.allyAdminProperties);
    return obj.removeAllyUser;
  }

  get allyAdminRemoveAllyUserBoolean(): boolean {
    if(this.allyAdminRemoveAllyUser == 'Y'){
      return true;
    }
    if(this.allyAdminRemoveAllyUser == 'N'){
      return false;
    }
  }

  get helpDeskRemoveAllyUser(): string {
    const obj = this.storeService.read<any>(SessionManagementAttrKey.helpDeskProperties);
    return obj.removeAllyUser;
  }

  get helpDeskRemoveAllyUserBoolean(): boolean {
    if(this.helpDeskRemoveAllyUser == 'Y'){
      return true;
    }
    if(this.helpDeskRemoveAllyUser == 'N'){
      return false;
    }
  }

  get allyUserRemoveAllyUser(): string {
    const obj = this.storeService.read<any>(SessionManagementAttrKey.allyUserProperties);
    return obj.removeAllyUser;
  }

  get allyUserRemoveAllyUserBoolean(): boolean {
    if(this.allyUserRemoveAllyUser == 'Y'){
      return true;
    }
    if(this.allyUserRemoveAllyUser == 'N'){
      return false;
    }
  }

  get showRemoveAllyUser(): boolean {
    if(this.isBranchUser) {
      const role = get(this.profile, 'allyRole');

      if((role == ProfileAllyRole.admin) && this.allyAdminRemoveAllyUserBoolean){
        return true;
      }
      if((role == ProfileAllyRole.helpdesk) && this.helpDeskRemoveAllyUserBoolean){
        return true;
      }
      if((role == ProfileAllyRole.user) && this.allyUserRemoveAllyUserBoolean){
        return true;
      }
      return false;
    }
    if(this.isDealershipUser) {
      return true;
    }
  }

  /*
  GETTERS for viewPending from allyUserPorperties in session
  */
  get allyAdminViewPending(): string {
    const obj = this.storeService.read<any>(SessionManagementAttrKey.allyAdminProperties);
    return obj.viewPending;
  }

  get allyAdminViewPendingBoolean(): boolean {
    if(this.allyAdminViewPending == 'Y'){
      return true;
    }
    if(this.allyAdminViewPending == 'N'){
      return false;
    }
  }

  get helpDeskViewPending(): string {
    const obj = this.storeService.read<any>(SessionManagementAttrKey.helpDeskProperties);
    return obj.viewPending;
  }

  get helpDeskViewPendingBoolean(): boolean {
    if(this.helpDeskViewPending == 'Y'){
      return true;
    }
    if(this.helpDeskViewPending == 'N'){
      return false;
    }
  }

  get allyUserViewPending(): string {
    const obj = this.storeService.read<any>(SessionManagementAttrKey.allyUserProperties);
    return obj.viewPending;
  }

  get allyUserViewPendingBoolean(): boolean {
    if(this.allyUserViewPending == 'Y'){
      return true;
    }
    if(this.allyUserViewPending == 'N'){
      return false;
    }
  }

  get showViewPending(): boolean {
    if(this.isBranchUser) {
      const role = get(this.profile, 'allyRole');

      if((role == ProfileAllyRole.admin) && this.allyAdminViewPendingBoolean){
        return true;
      }
      if((role == ProfileAllyRole.helpdesk) && this.helpDeskViewPendingBoolean){
        return true;
      }
      if((role == ProfileAllyRole.user) && this.allyUserViewPendingBoolean){
        return true;
      }
      if(this.isUnregisteredBranchUser){
        return true;
      }
      return false;
    }
    if(this.isDealershipUser) {
      return true;
    }
  }

  /*
  GETTERS for editViewPending from allyUserPorperties in session
  */
  get allyAdminEditViewPending(): string {
    const obj = this.storeService.read<any>(SessionManagementAttrKey.allyAdminProperties);
    return obj.editViewPending;
  }

  get allyAdminEditViewPendingBoolean(): boolean {
    if(this.allyAdminEditViewPending == 'Y'){
      return true;
    }
    if(this.allyAdminEditViewPending == 'N'){
      return false;
    }
  }

  get helpDeskEditViewPending(): string {
    const obj = this.storeService.read<any>(SessionManagementAttrKey.helpDeskProperties);
    return obj.editViewPending;
  }

  get helpDeskEditViewPendingBoolean(): boolean {
    if(this.helpDeskEditViewPending == 'Y'){
      return true;
    }
    if(this.helpDeskEditViewPending == 'N'){
      return false;
    }
  }

  get allyUserEditViewPending(): string {
    const obj = this.storeService.read<any>(SessionManagementAttrKey.allyUserProperties);
    return obj.editViewPending;
  }

  get allyUserEditViewPendingBoolean(): boolean {
    if(this.allyUserEditViewPending == 'Y'){
      return true;
    }
    if(this.allyUserEditViewPending == 'N'){
      return false;
    }
  }

  get showEditViewPending(): boolean {
    if(this.isBranchUser) {
      const role = get(this.profile, 'allyRole');

      if((role == ProfileAllyRole.admin) && this.allyAdminEditViewPendingBoolean){
        return true;
      }
      if((role == ProfileAllyRole.helpdesk) && this.helpDeskEditViewPendingBoolean){
        return true;
      }
      if((role == ProfileAllyRole.user) && this.allyUserEditViewPendingBoolean){
        return true;
      }
      if(this.isUnregisteredBranchUser){
        return true;
      }
      return false;
    }
    if(this.isDealershipUser) {
      return true;
    }
  }

  /*
  GETTERS for addSecDealerAppReports from allyUserPorperties in session
  */
  get allyAdminAddSecDealerAppReports(): string {
    const obj = this.storeService.read<any>(SessionManagementAttrKey.allyAdminProperties);
    return obj.addSecDealerAppReports;
  }

  get allyAdminAddSecDealerAppReportsBoolean(): boolean {
    if(this.allyAdminAddSecDealerAppReports == 'Y'){
      return true;
    }
    if(this.allyAdminAddSecDealerAppReports == 'N'){
      return false;
    }
  }

  get helpDeskAddSecDealerAppReports(): string {
    const obj = this.storeService.read<any>(SessionManagementAttrKey.helpDeskProperties);
    return obj.addSecDealerAppReports;
  }

  get helpDeskAddSecDealerAppReportsBoolean(): boolean {
    if(this.helpDeskAddSecDealerAppReports == 'Y'){
      return true;
    }
    if(this.helpDeskAddSecDealerAppReports == 'N'){
      return false;
    }
  }

  get allyUserAddSecDealerAppReports(): string {
    const obj = this.storeService.read<any>(SessionManagementAttrKey.allyUserProperties);
    return obj.addSecDealerAppReports;
  }

  get allyUserAddSecDealerAppReportsBoolean(): boolean {
    if(this.allyUserAddSecDealerAppReports == 'Y'){
      return true;
    }
    if(this.allyUserAddSecDealerAppReports == 'N'){
      return false;
    }
  }

  get showAddSecDealerAppReports(): boolean {
    if(this.isBranchUser) {
      const role = get(this.profile, 'allyRole');

      if((role == ProfileAllyRole.admin) && this.allyAdminAddSecDealerAppReportsBoolean){
        return true;
      }
      if((role == ProfileAllyRole.helpdesk) && this.helpDeskAddSecDealerAppReportsBoolean){
        return true;
      }
      if((role == ProfileAllyRole.user) && this.allyUserAddSecDealerAppReportsBoolean){
        return true;
      }
    }
    if(this.isDealershipUser) {
      return true;
    }
  }

  /*
  GETTERS for removeSecDealerAppReports from allyUserPorperties in session
  */
  get allyAdminRemoveSecDealerAppReports(): string {
    const obj = this.storeService.read<any>(SessionManagementAttrKey.allyAdminProperties);
    return obj.removeSecDealerAppReports;
  }

  get allyAdminRemoveSecDealerAppReportsBoolean(): boolean {
    if(this.allyAdminRemoveSecDealerAppReports == 'Y'){
      return true;
    }
    if(this.allyAdminRemoveSecDealerAppReports == 'N'){
      return false;
    }
  }

  get helpDeskRemoveSecDealerAppReports(): string {
    const obj = this.storeService.read<any>(SessionManagementAttrKey.helpDeskProperties);
    return obj.removeSecDealerAppReports;
  }

  get helpDeskRemoveSecDealerAppReportsBoolean(): boolean {
    if(this.helpDeskRemoveSecDealerAppReports == 'Y'){
      return true;
    }
    if(this.helpDeskRemoveSecDealerAppReports == 'N'){
      return false;
    }
  }

  get allyUserRemoveSecDealerAppReports(): string {
    const obj = this.storeService.read<any>(SessionManagementAttrKey.allyUserProperties);
    return obj.removeSecDealerAppReports;
  }

  get allyUserRemoveSecDealerAppReportsBoolean(): boolean {
    if(this.allyUserRemoveSecDealerAppReports == 'Y'){
      return true;
    }
    if(this.allyUserRemoveSecDealerAppReports == 'N'){
      return false;
    }
  }

  get showRemoveSecDealerAppReports(): boolean {
    if(this.isBranchUser) {
      const role = get(this.profile, 'allyRole');

      if((role == ProfileAllyRole.admin) && this.allyAdminRemoveSecDealerAppReportsBoolean){
        return true;
      }
      if((role == ProfileAllyRole.helpdesk) && this.helpDeskRemoveSecDealerAppReportsBoolean){
        return true;
      }
      if((role == ProfileAllyRole.user) && this.allyUserRemoveSecDealerAppReportsBoolean){
        return true;
      }
    }
    if(this.isDealershipUser) {
      return true;
    }
  }

  update(key: SessionManagementAttrKey, value: SessionManagementAttrValue): void {
    switch (key) {
      case SessionManagementAttrKey.profile:
        value = assign(this.profile, value); break;

      case SessionManagementAttrKey.sessionDetails:
        value = assign(this.sessionDetails, value); break;
    }

    this.storeService.write(key, value);
    this.handleStorageChange(key);
  }

  oktaAuthorize(options?: IOidcClientConfig): Promise<OktaToken> {
    let config = assign({
      scopes:       ['openid', 'profile'],
      clientId:     environment.oktaOAuth.clientId,
      sessionToken: this.sessionToken
    }, options);

    return this.oktaAuthService.requestToken(config);
  }

  authorize(): Promise<any> {

    return new Promise((resolve, reject) => {
      let config = assign({
        scopes:       ['openid', 'profile'],
        clientId:     environment.oktaOAuth.clientId,
        sessionToken: this.sessionToken
      });
      this.oktaAuthService.authorize(config)
        .then(details => this.transformUserDetails(details.resource))
        .then(profile => this.requestSessionDetails()
          .then(session => {
            this.update('sessionId', session.id);
            this.update('profile', { ...profile, userId: session.userId });
            this.updateAnalyticsWithProfile();
            resolve();
          }).catch(() => resolve())
        ).catch(err => reject(err));
    });
  }

  generateFingerprint(): Observable<string> {
    return this.oktaAuthService.generateFingerprint();
  }

  requestSessionDetails(): Promise<any> {
    return this.oktaAuthService.requestSession();
  }

  requestStatus(userName: string, isPublic: boolean = false): Observable<IBranchUserRequestStatus> {
    const baseUrl = environment.ndaServerURLS.workflowRegistration + (isPublic ? '/public' : '');
    const url = `${baseUrl}/workflow/request-status`;
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      // DRP-6323 - TODO: See if header needs to be removed, implications of removing.
      .set('userName', userName);

    return this.http.get<IBranchUserRequestStatus>(url, { headers })
      .map((branchRequestStatus: IBranchUserRequestStatus) => {
        this.branchUserRequestStatus = branchRequestStatus;
        return branchRequestStatus;
      });
  }

  /**
   * refreshSession (Method) - Used to refresh the user session by session Id
   * If active - refresh
   * non active - throws error
   */
  refreshSession(): Observable<{} | SessionModel[]> {
    if (!this.hasSession) {
      return Observable.of([]);
    }

    this.updateAnalyticsWithProfile();

    const url = `${environment.ndaServerURLS.sessionServices}/session/${this.sessionId}/refresh`;
    return this.http.post(url, null, this.httpOptions)
      .pipe(
        catchError((err) => {
          this.handleDestroySession();
          return this.handleError('refreshSession', []);
        })
      );
  }

  /**
   * deleteSession (Method) - Used to refresh the user session by session Id
   * If active - refresh
   * non active - throws error
   */
  deleteSession(): Observable<{} | SessionModel[]> {
    return this._deleteSession().pipe(
      flatMap(() => {
        // Check whether details for Apigee token are present. If they are not, use the registration logout URL.
        if (!!this.oktaAuthService.getApigeeJwt()) {
          const url = `${environment.ndaServerURLS.sessionServices}/session/logout`;
          return this.http.request('delete', url, {});
        } else {
          const url = `${environment.ndaServerURLS.registrationSessionServices}/session/logout`;
          return this.http.request('delete', url, {});
        }
      }),

      finalize(() => {
        // this.analytics.resetData();
        this.clearSessionDetails();
        this.handleDestroySession();
      })
    );
  }

  _deleteSession(): Observable<{} | SessionModel[]> {
    const isMitsubishiUser = includes(this.externalGroups, ExternalUserGroup.Mitsubishi);
    if (this.hasSession && (this.isBranchUser || isMitsubishiUser)) {
      return this.oktaAuthService.signout();
    }

    return Observable.of([]);
  }

  deleteSessionOktaSesion(): Observable<{} | SessionModel[]> {
    this.oktaAuthService.signout();
    return this._deleteSession().pipe(
      flatMap(() => {
        const url = `${environment.ndaServerURLS.registrationSessionServices}/session/logout`;
        return this.http.request('delete', url, {});
      }),

      finalize(() => {
        // this.analytics.resetData();
        this.clearSessionDetails();
        this.storeService.delete(SessionPersistenceAttrKey.HasActiveSession);
        this.storeService.delete(SessionManagementAttrKey.sessionId);
      })
    );
  }

  clearSessionDetails(): void {
    this.oktaAuthService.clearToken();
    this.oktaAuthService.clearApigeeJwt();
    this.storeService.delete('authCode');
    this.storeService.delete('verifier');
    this.storeService.delete('gateway-header');
    // Remove stored bookmarking service URL on logout.
    this.storeService.delete('redirectUrl');
    // Clears cookies for Ally User since they do not land on dealer login page after logout.
    this.clearCookies();
  }

  /*
    Attempts to remove cookies from storage on logout by overwriting them.
    Note: Cookies with HttpOnly flag set to true are not accessible via Javascript and will not be cleared.
  */
  clearCookies(): void {
    // Grabs names of all cookies.
    let cookies = document.cookie.split("; ");
    for (let c = 0; c < cookies.length; c++) {
      // Gets hostname for iteration over each subdomain.
      let d = window.location.hostname.split(".");
      while (d.length > 0) {
        let cookieEnd = '=; expires=Thu, 01-Jan-1970 00:00:01 GMT; domain=' + d.join('.') + ' ;path=';

        let cookie = cookies[c].split(";")[0].split("=")[0];
        let cookieBase = encodeURIComponent(cookie) + cookieEnd;
        cookie = cookie + cookieEnd;

        let p = location.pathname.split('/');
        // Attempts to clear cookies with base path / if applicable.
        document.cookie = cookie + '/';
        document.cookie = cookieBase + '/';
        // Attempts to clear cookies for each subpath of path grabbed from browser window.
        while (p.length > 0) {
          document.cookie = cookie + p.join('/');
          document.cookie = cookieBase + p.join('/');
          p.pop();
        };
        d.shift();
      }
    }
  }

  /**
   * allowDashboardNavigate (Method) - redirect to dashboard if login route is called with session active
   *
   */
  allowDashboardNavigate(): Observable<boolean> {
    return this.refreshSession().map(response => !!response['resource']);
  }

  private handleStorageChange(key) {
    if (key == SessionManagementAttrKey.sessionId) {
      this.hasSession$.next(this.hasSession);
    }
    else if (key == SessionPersistenceAttrKey.HasActiveSession) {
      let hasActiveSession = this.storeService.read(SessionPersistenceAttrKey.HasActiveSession);
      this.hasSession$.next(hasActiveSession as boolean);
    }
  }

   handleDestroySession(): void {
    this.storeService.delete(SessionPersistenceAttrKey.HasActiveSession);
    this.storeService.delete(SessionManagementAttrKey.sessionId);
    this.hasSession$.next(this.hasSession);
  }

  private transformUserDetails(details: IUserDetails): IProfile {
    let profile = details.profile;
    let pdn = details.primaryDealer;

    // add birthright property
    const groups = details.groups;
    let birthright = find(groups, group => includes(values(ProfileBirthrightAccess), group.name));
    assign(profile, { birthright: get(birthright, 'name') });
    // add ride return property
    let access = find(groups, group => includes(values(ProfileRideReturnGroup), group.name));
    assign(profile, { rideReturnGroup: get(access, 'name') });

    // temp: determine `dealerRole` until webservice does this
    if (pdn) {
      let dealerGroup = find(groups, { name: `DLR_${pdn}` }),
        // logic changed to determine Dealer Admin.
        // Now an user can be ADMIN for either primary or secondary pdn
        dealerAdmin = filter(groups, function (dlrs) {
          return startsWith(dlrs.name, `ADM_`);
        });
      let exernalGroups = map(filter(groups, group => includes(values(ExternalUserGroup), group.name)), 'name');

      assign(profile, {
        exernalGroups,
        birthright: get(birthright, 'name'),
        rideReturnGroup: get(access, 'name'),
        dealership: { pdn, name: get(dealerGroup, 'description', '') },
        dealerRole: dealerAdmin.length > 0 ? ProfileDealerRole.admin : ProfileDealerRole.user
      });
    }

    return profile;
  }

  /**
   * Updating user in digital data object
   */
  private updateAnalyticsWithProfile(): void {
    this.analytics.updateUserObject(this.userObject);
  }

  /**
   * Provides session data to map user object
   */
  get userObject(){
    const attributes = {
      dealerUserId: this.profile.userId,
      userType: this.isDealershipUser ? 'Dealer' : 'Ally',
      pdn: this.checkSession('pdn'),
      dealershipName: this.checkSession('dealershipName'),
      userRole: this.profile.allyRole || this.profile.dealerRole,
    }
    return attributes;
  }

  checkSession(session:any) {
    if(this.isDealershipUser){
      switch(session){
        case 'dealershipName': return this.profile.dealership.name;
        case 'pdn': return this.profile.dealership.pdn;
        default: return ''
      }
    }
    return '';
  }

  /**
   * Accessor to return userRole
   */
  get userRole(): string {
    if (this.isBranchUser) {
      return get(this.profile, 'allyRole');
    } else if (this.isDealershipUser) {
      return get(this.profile, 'dealerRole');
    }
  }

  get isHelpDesk() :boolean{
    return this.userRole == ProfileAllyRole.helpdesk;
  }

  get allyRole() :string{
    return get(this.profile, 'allyRole');
  }

  get dealerRole() :string{
    return get(this.profile, 'dealerRole');
  }

  get isUnregisteredBranchUser(): boolean {
    if(this.isBranchUser
      && !this.allyRole
      && !this.dealerRole) {
      return true;
    }
    return false;
  }

  get isUnregisteredDealerUser(): boolean {
    if(this.isDealershipUser
      && !this.allyRole
      && !this.dealerRole) {
      return true;
    }
    return false;
  }

}