import { Component, OnInit, Input } from '@angular/core';

import { DynamicContact } from '../../models/dynamic-contact';
import { GeneralContact } from '../../models/general-contact';
import { AppStrings } from '../../../../assets/app-strings/app-strings';


@Component({
  selector: 'app-contact-detail',
  templateUrl: './contact-detail.component.html',
  styleUrls: ['./contact-detail.component.scss']
})
export class ContactDetailComponent implements OnInit {
  readonly CONSTANTS = {
    ...AppStrings['myContacts']['contactDetail'],
  };

  @Input() contact: DynamicContact | GeneralContact;
  @Input() layout: 'horizontal' | 'vertical';

  constructor() { }

  ngOnInit() { }

  isDynamicContact(contact: DynamicContact | GeneralContact| null | undefined): contact is DynamicContact {
    if (contact === null || contact === undefined) {
      return false;
     }
  return (contact as DynamicContact).contactName !== undefined;
  }

  hasContactName(contact: DynamicContact | GeneralContact): contact is DynamicContact {
    if (contact === null || contact === undefined) {
      return false;
     }
    return (<DynamicContact>contact).contactName !== undefined && (<DynamicContact>contact).contactName !== null;
  }

  hasContactPhone(contact: DynamicContact | GeneralContact): contact is DynamicContact {
    if (contact === null || contact === undefined) {
      return false;
     }
    return (<DynamicContact>contact).ph !== undefined && (<DynamicContact>contact).ph !== null;
  }

  hasContactEmail(contact: DynamicContact | GeneralContact): contact is DynamicContact {
    if (contact === null || contact === undefined) {
      return false;
     }
    return (<DynamicContact>contact).email !== undefined && (<DynamicContact>contact).email !== null;
  }

  get columns(): string {
    return this.layout === 'horizontal' ? 'col-4' : 'col-12';
  }

  get row(): string {
    return this.layout === 'horizontal' ? 'row' : '';
  }
} 
