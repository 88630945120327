<div class="modal-content" data-track-region="PDN Not Found in Selected State" tabindex="-1" outline:none>
  <div class="modal-body">
    <div class="close-button">
      <button type="button" class="btn close" (click)="hide()" aria-label="Close" data-track-name="Close"
        data-track-trigger="close">&times;</button>         
    </div>   
    <div class="col-lg-12 d-flex title">      
      <h4 class="modal-title">{{CONSTANTS.title}}</h4>
    </div>
    <div class="col-lg-12 col-sm-12 col-xs-12 message">
      <p>{{CONSTANTS.message}}</p>
    </div>
    <div class="col-lg-12 col-sm-12 col-xs-12 button">
      <button type="button" (click)="hide()" class="btn btn-primary font-weight-bold try-again" data-track-elem="button"
        data-track-name="Go Back" data-track-trigger="PDN Not Found in Selected State" aria-label="try again">{{CONSTANTS.tryAgain}}</button>
    </div>
  </div>

  <div class="col-lg-6">
    <template #errorForgotPwdModalContainer class="template"></template>
  </div>

</div>