import { Injectable, Inject, NgZone } from "@angular/core";
import { DOCUMENT } from "@angular/common";
import "rxjs/add/operator/finally";
import allytm from "@allysf/allytm";

import {
  IDigitalDataOptionsPage,
  CustomEventBody,
  DigitalDataLoadRules,
} from "./analytics.interface";
import * as _ from "lodash";

@Injectable()
export class AnalyticsService {
  private pageInstanceId = "{environment}-_-{pageName}";
  private version = "Ally_1.4";
  private user = {};

  constructor(@Inject(DOCUMENT) private doc: any, private ngZone: NgZone) {
    allytm();

    window["digitalData"] = {
      pageInstanceId: this.pageInstanceId,
      delayPageLoadEvent: true,
      loadRules: {
        adobeAnalytics: true,
        adobeAudienceManager: true,
        adobeTarget: false,
        celebrus: false,
        confirmit: true,
        googleAnalytics: false,
        livePerson: true,
      },
      page: {
        pageInfo: [
          {
            pageID: "",
            pageName: "",
            destinationURL: document.location.href,
            referringURL: document.referrer,
            sysEnv: window.orientation ? "mobile" : "desktop",
            language: document.getElementsByTagName("html").item(0)
              ? document
                  .getElementsByTagName("html")
                  .item(0)
                  .lang.substring(0, 2)
              : "Unknown",
          },
        ],
        category: {
          primaryCategory: "ADOS",
          site: "ADOS",
          section: "",
          subsection: "",
          lineOfBusiness: "Auto Dealer",
        },
        attributes: {},
      },
      product: [],
      cart: {},
      transaction: {},
      event: [],
      component: [],
      user: {},
      privacy: {},
      version: this.version,
    };
  }

  // Custom events, only errors.
  trackEndPoint(data: { errors: string | string[] }): void {
    try {
      this.ngZone.runOutsideAngular(() => {
        // window['allytm']['event']('customEvent', data.errors);
        this.ngZone.run(() => {
          console.log("trackEndPoint");
        });
      });
    } catch (error) {
      console.error("TAGGING_SERVICE_NOT_LOADED_PROPERLY");
    }
  }

  // Only errors.
  trackError(error: string): void {
    try {
      this.ngZone.runOutsideAngular(() => {
        window["allytm"]["event"]("customError", error);
        this.ngZone.run(() => {
          console.log("trackError", error);
        });
      });
    } catch (error) {
      console.error("TAGGING_SERVICE_NOT_LOADED_PROPERLY");
    }
  }

  // Modal view events.
  trackModal(title, body): void {
    try {
      this.ngZone.runOutsideAngular(() => {
        if (window["digitalData"])
          window["allytm"]["event"]("modalView", title, {
            treatAsPageview: true,
            msg: body,
          });
        this.ngZone.run(() => {
          console.log("trackModal - ", title);
        });
      });
    } catch (error) {
      console.error("TAGGING_SERVICE_NOT_LOADED_PROPERLY");
    }
  }

  // Custom Search events, outside of errors.
  trackEvent(title: string, attributes: CustomEventBody): void {
    try {
      this.ngZone.runOutsideAngular(() => {
        window["allytm"]["event"](title, attributes);
        this.ngZone.run(() => {
          console.log("trackEvent - ", title);
        });
      });
    } catch (error) {
      console.error("TAGGING_SERVICE_NOT_LOADED_PROPERLY");
    }
  }

  // Page view events.
  trackPage(data: IDigitalDataOptionsPage): void {
    try {
      this.ngZone.runOutsideAngular(() => {
        if (window["digitalData"])
          window["allytm"]["event"]("pageview", data.title, {
            ...data.attributes,
            user: this.userObject,
          });
        this.ngZone.run(() => {
          console.log("trackPage - ", data.title);
        });
      });
    } catch (error) {
      console.error("TAGGING_SERVICE_NOT_LOADED_PROPERLY");
    }
  }

  // Click events
  trackClick(tagName: string, name: string): void {
    try {
      this.ngZone.runOutsideAngular(() => {
        window["allytm"]["clickEvent"]({ tagName, name });
        this.ngZone.run(() => {
          console.log("trackClick - ", name);
        });
      });
    } catch (error) {
      console.error("TAGGING_SERVICE_NOT_LOADED_PROPERLY");
    }
  }

  updateUserObject(user: any) {
    // Store user object if nonempty
    if (!_.isEmpty(user)) {
      sessionStorage.setItem("taggingUser", JSON.stringify(user));
      this.user = user;
    }
  }

  // Return user object from session storage
  get userObject() {
    return JSON.parse(sessionStorage.getItem("taggingUser"));
  }
}