import { Injectable } from '@angular/core';
import { AppConstantsService } from './app-constants.service';

@Injectable()
export class StoreService {

  constructor(private constantsService: AppConstantsService) { }

  private getStoreByKey(key: string): Storage {
    switch(key) {
      case 'hasActiveSession':
      case 'willDeleteSession':
        return localStorage;

      default:
        return sessionStorage;
    }
  }

  write(key: string, value: any) {
    if (value) {
      value = JSON.stringify(value);
    }
    this.getStoreByKey(key).setItem(key, value);
  }

  read<T>(key: string): T {
    let value: string = this.getStoreByKey(key).getItem(key);

    if (value && value != "undefined" && value != "null") {
      return <T>JSON.parse(value);
    }

    return null;
  }

  delete(key: string): void {
    this.getStoreByKey(key).removeItem(key);
  }

  keyExist(key: string): boolean {
    return this.getStoreByKey(key).getItem(key) !== null;
  }

  clear(): void {
    this.delete(this.constantsService.session_id);
    this.delete(this.constantsService.isActive);
    this.delete(this.constantsService.pdn);
    this.delete(this.constantsService.sessionDetails);
    this.delete(this.constantsService.sessionToken);
    this.delete(this.constantsService.profile);
  }

  /*
    Checks for values to exist in storage.
    Calls a callback function if the "key" is found within "maxRetries" tries.
    Stops the interval function if the "key" is not found within "maxRetries" tries.
  */
  waitForStorageValue(key: string, callback: Function): void {
    // Interval function is allowed to run a maximum of "maxRetries" times.
    const maxRetries: number = 10;
    let currentTry: number = 0;

    const intervalId = setInterval(() => {
      const value = sessionStorage.getItem(key);
      // Increment number of tries with each new interval.
      currentTry++;
      if (value !== null) {
        clearInterval(intervalId);
        // Call callback function if value exists.
        callback();
      } else if (currentTry === maxRetries) {
        // Break interval function if value does not exist after "maxRetries" tries.
        console.log(`Value not found from store service after ${currentTry} tries.`);
        clearInterval(intervalId);
        // Call callback function. Default values will be used.
        callback();
      }
    }, 1000); // Check every 1 second
  }
}
