import { Component, OnInit, ViewChild } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Router } from '@angular/router';
import { AppStrings } from '../../../../assets/app-strings/app-strings';
import { AppConstantsService } from '../../../services/app-constants.service';

@Component({
  selector: 'dealership-mismatched-error-modal',
  templateUrl: './dealership-mismatched-error-modal.component.html',
  styleUrls: ['./dealership-mismatched-error-modal.component.scss']
  })
  export class DealershipMismatchedErrorModalComponent implements OnInit {
    public readonly CONSTANTS = AppStrings['registration']['dealership']['step2']['errorMessages']['mismatchPdnState'];

    modalRef: BsModalRef;

    constructor(
      private router: Router,
      private appConstantsService: AppConstantsService, 
      private modalService: BsModalService ) { }
  
    ngOnInit() {
    }
  
    public hide(){
        this.modalRef.hide();
    }
  }
  